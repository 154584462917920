import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { i18n } from "@lingui/core";
import { detect, fromHtmlTag } from "@lingui/detect-locale";
import { I18nProvider } from "@lingui/react";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { loadCatalog } from "./modules/lingui/lingui";

async function main() {
	const {
		ENV: { SENTRY_DSN },
	} = window as unknown as { ENV: { SENTRY_DSN: string | undefined } };

	if (SENTRY_DSN) {
		Sentry.init({
			dsn: SENTRY_DSN,
			tracesSampleRate: 0,
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 0,
			tunnel: "/tunnel",
			integrations: [
				Sentry.browserTracingIntegration({
					useEffect,
					useLocation,
					useMatches,
				}),
			],
		});
	}

	const locale = detect(fromHtmlTag("lang")) || "id";

	await loadCatalog(locale);

	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<I18nProvider i18n={i18n}>
					<RemixBrowser />
				</I18nProvider>
			</StrictMode>,
		);
	});
}

main();
